import React, { useState } from 'react';
import { Button, Row, Col, Form } from 'react-bootstrap';
import { submitAnswers, submitReferral } from '../api/submit';
import "../questionnaire.scss";

const DecisionTree = () => {
    const [state, setState] = useState({
        currentNode: 'headhit_Front_of_Head',
        answers: {},
        score: null,
        decision: null,
    });

    const [tooltip, setTooltip] = useState("")

    const decisionTree = {
        // Left side logic
        headhit_Front_of_Head: {
            question: 'Did the player hit the front of their head?',
            yes: 'headhit_Back',
            no: 'imm_symp_Incoherent_Speech_1',
        },
        imm_symp_Incoherent_Speech_1: {
            question: 'Does the player experience incoherent speech?',
            yes: 'headhit_Back_of_Head',
            no: 'headhit_Back_left',
        },
        headhit_Back_left: {
            question: 'Did the player hit the back of their body?',
            yes: 'imm_symp_Confusion_2',
            no: 'imm_symp_Confusion_1',
        },
        imm_symp_Confusion_1: {
            question: 'Does the player experience confusion?',
            yes: 'end_0_278',
            no: 'end_0_2251',
        },
        imm_symp_Confusion_2: {
            question: 'Does the player experience confusion?',
            yes: 'end_0_3286',
            no: 'end_0_2668',
        },
        headhit_Back_of_Head: {
            question: 'Did the player hit the back of their head?',
            yes: 'end_0_3614',
            no: 'imm_symp_Dazed_or_Vacant_Stare',
        },
        imm_symp_Dazed_or_Vacant_Stare: {
            question: 'Does the player have a dazed or vacant stare?',
            yes: 'end_0_3265',
            no: 'end_0_2907',
        },
        headhit_Back: {
            question: 'Did the player hit the back of their body?',
            yes: 'imm_symp_Incoherent_Speech_2',
            no: 'imm_symp_Memory_Loss',
        },
        imm_symp_Memory_Loss: {
            question: 'Does the player experience memory loss?',
            yes: 'imm_symp_Incoherent_Speech_3',
            no: 'end_0_2671',
        },
        imm_symp_Incoherent_Speech_3: {
            question: 'Does the player experience incoherent speech?',
            yes: 'end_0_3709',
            no: 'end_0_3244',
        },
        imm_symp_Incoherent_Speech_2: {
            question: 'Does the player experience incoherent speech?',
            yes: 'headhit_Top_of_Head',
            no: 'end_0_3952',
        },
        headhit_Top_of_Head: {
            question: 'Did the player hit the top of their head?',
            yes: 'end_0_4674',
            no: 'end_0_4197',
        },
        // End nodes (predictions)
        end_0_3614: { prediction: '0.3614', decision: 'Pull' },
        end_0_2671: { prediction: '0.2671', decision: 'Play' },
        end_0_278: { prediction: '0.278', decision: 'Play' },
        end_0_2251: { prediction: '0.2251', decision: 'Play' },
        end_0_3952: { prediction: '0.3952', decision: 'Pull' },
        end_0_4674: { prediction: '0.4674', decision: 'Pull' },
        end_0_4197: { prediction: '0.4197', decision: 'Pull' },
        end_0_2907: { prediction: '0.2907', decision: 'Play' },
        end_0_3265: { prediction: '0.3265', decision: 'Pull' },
        end_0_3709: { prediction: '0.3709', decision: 'Pull' },
        end_0_3244: { prediction: '0.3244', decision: 'Pull' },
        end_0_2668: { prediction: "0.2668", decision: 'Play' },
        end_0_3286: { prediction: "0.3286", decision: 'Pull' }
    };

    const handleAnswer = (answer) => {
        const nextNode = decisionTree[state.currentNode][answer];
        const newAnswers = { ...state.answers, [state.currentNode]: answer === 'yes' };

        if (nextNode.startsWith('end_')) {
            const { prediction } = decisionTree[nextNode];
            const decision = prediction > 0.3 ? 'Pull' : 'Play';
            setState({ ...state, score: prediction, decision, answers: newAnswers });
            handleSubmit(newAnswers, prediction, decision);
        } else {
            setState({ ...state, currentNode: nextNode, answers: newAnswers });
        }
    };

    const restart = () => {
        setState({ currentNode: 'headhit_Front_of_Head', answers: {}, score: null, decision: null });
    };

    const handleSubmit = async (answers, score, decision) => {
        const isDecisionPull = decision === 'Pull' ? false : true;

        const data = {
            userId: localStorage.getItem('userId'),
            answers,
            score,
            decision: isDecisionPull
        }

        try {
            const res = await submitAnswers(data);
        } catch (err) {
            console.log(err.response ? err.response.data.message : err.message);
        }
    };

    const renderAnsweredQuestions = () => {
        return Object.keys(state.answers).map((key, index) => (
            <div key={index} style={{ marginBottom: '10px', display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ color: 'black', flex: '1', marginRight: '10px' }}>
                    {decisionTree[key].question}
                </div>
                <div style={{ flexShrink: '0', color: state.answers[key] ? 'green' : 'red', fontWeight: 'bold' }}>
                    {state.answers[key] ? 'Yes' : 'No'}
                </div>
            </div>
        ));
    };

    const handleReferral = async (event) => {
        const data = {
            userId: localStorage.getItem('userId'),
            recipientEmail: event.target.form.email.value.toLowerCase()
        }
        await submitReferral(data)
        event.target.form.reset()
        setTooltip(`Sent referral to ${data.recipientEmail}!`)
    }

    return (
        <div style={{ textAlign: 'left', marginTop: 20 }}>
            <h3>Injury Impact Severity Score</h3>
            <div>{renderAnsweredQuestions()}</div>
            <div className="container-style">
                {state.score === null ? (
                    <div className="question-container-style">
                        <p className='question-style'>Q. {decisionTree[state.currentNode].question}</p>
                        <div className="button-container">
                            <Button className="button-style" variant='primary' onClick={() => handleAnswer('yes')}>Yes</Button>
                            <Button className="button-style" variant='light' active onClick={() => handleAnswer('no')}>No</Button>
                        </div>
                    </div>
                ) : (
                    <div>
                        <div className="decision-text">
                            Recommendation: <strong>{state.decision === 'Pull' ? 'PULL the player for further assessment' : 'PLAY the player'}</strong>
                        </div>
                        <div className="score-circle" style={{ backgroundColor: state.decision === 'Pull' ? '#f44336' : '#4caf50' }}>{state.score}</div>
                        {/* <div className="score-text">Impact Injury Severity Score (IISS)</div> */}
                        {/* <div className="help-text">
                            The IISS provides a quick way to understand the severity of a traumatic brain injury (TBI).
                            A higher score indicates a more severe injury. This score helps patients and clinicians
                            track the patient's recovery progress and make informed decisions about treatment.
                        </div> */}
                        <div>
                            <Form>
                                <div className="decision-text"> Enter the person's email to refer to Sallie to track their symptoms </div>
                                <Row>
                                    <Col>
                                        <Form.Control type="email" name="email" placeholder="Enter email" />
                                    </Col>
                                    <Col>
                                        <Button variant='primary' onClick={handleReferral}>Refer to Sallie</Button>
                                    </Col>
                                </Row>
                                <Form.Label>{tooltip}</Form.Label>
                            </Form>
                        </div>
                    </div>
                )}
            </div>
            <div style={{ textAlign: 'center' }}>
                <button className="restart-button" onClick={restart}>Restart</button>
            </div>
        </div>
    );
};

export default DecisionTree;

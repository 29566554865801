import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import SubmitButton from "../StyledComponents/SubmitButton"
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import TermsAndConditions from './TermsAndConditions';

import { registerUser } from '../../api/auth';

import './auth.scss';

const SignUp = () => {
    const navigate = useNavigate();
    const [termsAgreed, setTermsAgreed] = useState(false);
    const [showTermsAndConditions, setShowTermsAndConditions] = useState(false);

    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        dob: '',
        userType: '',
        postalCode: '',
        password: '',
        confirmPassword: ''
    });
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);

        if (formData.password !== formData.confirmPassword) {
            setErrorMessage("Passwords do not match");
            setIsSubmitting(false);
            return;
        }

        if (!termsAgreed) {
            setErrorMessage("Please agree to the Terms and Conditions");
            setIsSubmitting(false);
            return;
        }

        const defaultCity = "not required";
        const defaultState = "not required";

        const dataToSubmit = {
            ...formData,
            city: defaultCity,
            state: defaultState,
            email: formData.email.toLowerCase()
        };

        try {
            let result = await registerUser(dataToSubmit);
            if (result?.status === 200) {
                localStorage.setItem('userId', result.data.userId);
                navigate("/questions");
            }
        } catch (error) {
            console.error("Registration error:", error);
            if (error?.response?.data?.message) {
                setErrorMessage(error?.response?.data?.message);
            } else {
                setErrorMessage("Registration failed, Please try again");
            }
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <>
            <div style={{ display: showTermsAndConditions ? "none" : "block" }}>
                <div className="form-header">
                    <h4>
                        Welcome to the Injury Impact Severity Score (IISS)
                        triage tool which provides on-field clinical decision support
                    </h4>
                    <h4 className='mt-3'>Create an account</h4>
                </div>
                <Form onSubmit={handleSubmit} className="form-container">
                    <Form.Group className="mb-3">
                        <Form.Label>First Name<span className="required">*</span></Form.Label>
                        <Form.Control
                            type="text"
                            name="firstName"
                            value={formData.firstName}
                            onChange={handleChange}
                            placeholder="Enter First Name"
                            required
                        />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Last Name<span className="required">*</span></Form.Label>
                        <Form.Control
                            type="text"
                            name="lastName"
                            value={formData.lastName}
                            onChange={handleChange}
                            placeholder="Enter Last Name"
                            required
                        />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Email Address<span className="required">*</span></Form.Label>
                        <Form.Control
                            type="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            placeholder="Enter Email"
                            required
                        />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Date of Birth<span className="required">*</span></Form.Label>
                        <Form.Control
                            type="date"
                            name="dob"
                            value={formData.dob}
                            onChange={handleChange}
                            placeholder="Enter Date of Birth"
                            required
                        />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>User Type<span className="required">*</span></Form.Label>
                        <Form.Select
                            name="userType"
                            value={formData.userType}
                            onChange={handleChange}
                            aria-label="Select a User Type"
                            required
                        >
                            <option>Select a User Type</option>
                            <option value="Athletic Trainer">Athletic Trainer</option>
                            <option value="Chiropractor">Chiropractor</option>
                            <option value="Coach">Coach</option>
                            <option value="Orthopedist">Orthopedist</option>
                            <option value="Sports Medicine">Sports Medicine</option>
                            <option value="Other">Other</option>
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Postal Code<span className="required">*</span></Form.Label>
                        <Form.Control
                            type="text"
                            name="postalCode"
                            value={formData.postalCode}
                            onChange={handleChange}
                            placeholder="Enter Postal Code"
                            required
                        />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Password<span className="required">*</span></Form.Label>
                        <Form.Control
                            type="password"
                            name="password"
                            value={formData.password}
                            onChange={handleChange}
                            minLength={8}
                            placeholder="Password"
                            required
                        />
                    </Form.Group>
                    <Form.Group className='mb-4'>
                        <Form.Label>Confirm Password<span className="required">*</span></Form.Label>
                        <Form.Control
                            type="password"
                            name="confirmPassword"
                            value={formData.confirmPassword}
                            onChange={handleChange}
                            placeholder="Confirm Password"
                            required
                        />
                    </Form.Group>

                    {errorMessage &&
                        <>
                            <p className="error-message">{errorMessage}</p>
                        </>
                    }

                    <p className='terms-agree'>
                        Please accept the <span className='terms-link' onClick={() => setShowTermsAndConditions(true)}>terms and agreements</span>.
                    </p>

                    <Button className="w-100" variant="primary" type="submit" disabled={isSubmitting}>
                        {isSubmitting ? 'Submitting...' : 'Register'}
                    </Button>
                </Form >

                <div className="form-footer">
                    <p>
                        Already have an account?
                    </p>
                    <SubmitButton type="button" centered onClick={() => navigate("/")}>
                        Login
                    </SubmitButton>
                </div>
            </div>
            <div style={{ display: showTermsAndConditions ? "block" : "none" }}>
                <TermsAndConditions
                    firstName={formData.firstName}
                    lastName={formData.lastName}
                    termsAgreed={termsAgreed}
                    setTermsAgreed={setTermsAgreed}
                    setShowTermsAndConditions={setShowTermsAndConditions}
                    setErrorMessage={setErrorMessage}
                />
            </div>
        </>
    );
}

export default SignUp;

import React from "react"
import SubmitButton from "../StyledComponents/SubmitButton"
import "./auth.scss";

const TermsAndConditions = ({ firstName, lastName, termsAgreed, setTermsAgreed, setShowTermsAndConditions, setErrorMessage }) => {
    return (
        <>
            <div className='terms-container'>
                <h3>
                    Power of Patients <br />
                    SUBSCRIPTION AGREEMENT <br />
                    (Provider and Therapist)
                </h3>
                <p>Last Updated: Nov 13, 2023</p>
                <p> Welcome!</p>
                <h4>INTRODUCTION</h4>
                <p>
                    This Subscription Agreement (the "Agreement") is entered into between
                    Power of Patients a LLC (hereinafter referred to as the "Company"), and
                    the entity or individual agreeing to these terms (the "Subscriber"),
                    collectively referred to as the "Parties."
                </p>
                <p>
                    Subject to the terms of this Agreement, the Company will provide to the
                    Subscriber online use of the Sallie® and any other branded products that
                    is in the Order Form, as more fully set forth below.
                </p>
                <p>
                    <strong>
                        ANY TRANSFER OR MODIFICATION OF, OR ACCESS TO, RECORDS OF ANY PERSON
                        WHOM A SUBSCRIBER OR A SUBSCRIBER’S AUTHORIZED USERS OR GRANTING
                        PERMISSION TO OTHER PERSONS ACCESS TO SUCH RECORDS MAY REQUIRE ONE OR
                        MORE CONSENTS SIGNED BY THE SUBSCRIBER AND/OR A SUBSCRIBER’S
                        AUTHORIZED USERS.
                    </strong>
                </p>
                <p>
                    The <strong>Power of Patients Consent</strong> is available at www
                    powerofpatients.com/consent and consent to be executed by the person who
                    is subject to services from the Subscriber or the Authorized User
                    sharing certain data and information. This may be supplemented or
                    modified to reflect applicable situations.
                </p>

                <h4>1. DEFINITIONS </h4>

                <p>
                    1.1 "Product" includes any Power of Patients (Company”) branded
                    materials and apps that we offer, as well as any related Product such as
                    third-party apps and products that we make available to members,
                    including any updates that may be delivered to the Subscriber by the
                    Company, .
                </p>
                <p>
                    1.2 "Fee" refers to the fee paid periodically as agreed by the
                    Subscriber for the right to access and use the Product, as set forth in
                    Section 3.
                </p>

                <h4>2. GRANT OF LICENSE; NOTICES </h4>
                <p>
                    2.1 Subject to the terms and conditions of this Agreement, during the
                    Term, the Company hereby grants the Subscriber and each Authorized User
                    a non-exclusive, non-transferable license right to access and use the
                    Product. No other rights are granted unless expressly set forth herein.
                </p>
                <p>
                    By agreeing to this Agreement and/or by using the Product, the
                    Subscriber and each Authorized User is also agreeing to the terms of the
                    Privacy Policy www powerofpatients.com/privacy and the Terms of Use
                    www.powerofpatients.com /terms, both of which are incorporated into and
                    deemed a part of this Agreement. The same rules that apply regarding
                    changes and revisions of this agreement also apply to changes and
                    revisions of the Privacy Policy and the Terms of Use. It is important
                    that the Subscriber and any Authorized User read and understand both the
                    Privacy Policy and the Terms of Use.
                </p>
                <p>2.2 NOTICES.</p>
                <p>
                    <strong>
                        IF THE SUBSCRIBER OR ANY AUTHORIZED USER BECOMES AWARE OF ANY PERSON
                        WHOM MAY BE USING THE PRODUCT WITH WHOM THE SUBSCRIBER OR ANY
                        AUTHORIZED USER MAY BE WORKING IS THINKING ABOUT SUICIDE OR
                        CONSIDERING HARMING THEMSELVES OR OTHERS OR IF THE SUBSCRIBER BELIEVES
                        OR HAS DETERMINED THAT ANY OTHER PERSON WHOM MAY BE USING THE PRODUCT
                        WITH WHOM THE SUBSCRIBER OR ANY AUTHORIZED USER MAY BE WORKING MAY BE
                        IN ANY DANGER OR IF SUBSCRIBER OR ANY AUTHORIZED USER HAVE ANY MEDICAL
                        EMERGENCY, THE SUBSCRIBER AND THE AUTHORIZED USER MUST IMMEDIATELY
                        CALL THE EMERGENCY SERVICE NUMBER (911 IN THE US)
                    </strong>{" "}
                    AND NOTIFY THE RELEVANT AUTHORITIES. SEEK IMMEDIATE IN PERSON
                    ASSISTANCE. THE PLATFORM IS NOT DESIGNED FOR USE IN ANY OF THE
                    AFOREMENTIONED CASES AND CANNOT PROVIDE THE ASSISTANCE REQUIRED IN ANY
                    OF THE AFOREMENTIONED CASES.
                </p>
                <p>
                    <strong>
                        THE PLATFORM IS NOT INTENDED FOR THE PROVISION OF CLINICAL DIAGNOSIS
                    </strong>{" "}
                    AND NEITHER THE SUBSCRIBER OR ANY AUTHORIZED USER SHOULD USE IT IF THE
                    SUBSCRIBER OR AUTHORIZED USER NEEDS ANY OFFICIAL DOCUMENTATION OR
                    APPROVALS FOR PURPOSES SUCH AS, BUT NOT LIMITED TO, COURT-ORDERED
                    COUNSELING OR EMOTIONAL SERVICE DOG CERTIFICATION. IT IS ALSO NOT
                    INTENDED FOR ANY INFORMATION REGARDING WHICH DRUGS OR MEDICAL TREATMENT
                    MAY BE APPROPRIATE IN ANY GIVEN SITUATION, AND THE SUBSCRIBER AND ANY
                    AUTHORIZED USERS SHOULD DISREGARD ANY SUCH ADVICE IF DELIVERED THROUGH
                    THE PLATFORM.
                </p>
                <p>
                    <strong>
                        ALWAYS SEEK THE ADVICE OF A PHYSICIAN OR OTHER QUALIFIED HEALTH
                        PROVIDER WITH SPECIFIC KNOWLEDGE AND QUALIFICATIONS REGARDING ANY
                        QUESTIONS ABOUT A MEDICAL CONDITION PERSON WHO MAY BE USING THE
                        PRODUCT WITH WHOM THE SUBSCRIBER OR ANY AUTHORIZED USER MAY BE
                        WORKING. DO NOT DISREGARD, AVOID, OR DELAY IN DELIVERING OR REFERRING
                        PROPER CARE FROM A DOCTOR OR OTHER QUALIFIED PROFESSIONAL BECAUSE OF
                        INFORMATION RECEIVED BY THE SUBSCRIBER THROUGH THE PRODUCT.
                    </strong>
                </p>
                <p>
                    <strong>
                        THE COMPANY IS COMMITTED TO IMPROVING HEALTHCARE. THEREFORE, BY USING
                        THIS PRODUCT THE SUBSCRIBER, FOR ITSELF AND ON BEHALF OF EACH
                        AUTHORIZED USER, AGREE TO THE TERMS OF USE AND THE PRIVACY POLICY
                        INCLUDING WITHOUT LIMITATION THE SECTION ON HOW THE COMPANY MAY SHARE
                        ANONYMIZED DATA BASED ON THE INFORMATION COLLECTED THROUGH THE
                        PRODUCT.
                    </strong>
                </p>

                <h4>3. LICENSE FEE AND PAYMENT </h4>

                <p>
                    3.1 In consideration for the license granted herein, the Subscriber
                    agrees to pay the Company the applicable Fee, as specified in the
                    Membership Payment terms. The Fee shall be paid in accordance with the
                    payment terms as specified in on the Site using the application licensed
                    to provide such payments, which is currently Stripe but may be charged
                    in the future without notice. If Subscriber has provided the Company or
                    its authorized representative a credit card for purposes of payment
                    hereunder, then the Company or its authorized representative may, and
                    Subscriber authorizes Company or its authorized representative to,
                    charge all amounts owed to Company hereunder to such credit card as such
                    amounts become due.{" "}
                    <strong>
                        If any Subscriber or Authorized User is getting the right to access
                        the Product as a result of the authorization granted to such person by
                        a third party who is permitted by the Company (“Authorized Third
                        Party”) to grant Subscriber or Authorized User authorization, then
                        this Section 3.1 and 3.2 may not apply to Subscriber or Authorized
                        User unless the Subscriber is paying for the Product as well.
                    </strong>
                </p>
                <p>
                    3.2 All payments due hereunder shall be net of any applicable sales,
                    use, and other taxes, and the Subscriber agree to pay (or reimburse
                    Company or its authorized representative, as the case may be) any taxes
                    due in connection with this Agreement, excluding taxes on Company’s or
                    its authorized representative’s income; provided, however, that Company
                    and its authorized representatives shall not invoice the Subscriber for
                    any taxes for which the Subscriber have provided an appropriate
                    exemption certificate for the applicable delivery jurisdiction.
                </p>
                <p>
                    3.3 If Subscriber fails to pay any sum for access to this Product as and
                    when due, then the Company shall be free to suspend or discontinue
                    performance under this Agreement, at its discretion, and stop access to
                    the Product without any further notice. Company reserves the right to
                    charge and collect a service fee on any unpaid, past-due license fee
                    amounts equal to the lesser of one and one-half percent (1½ %) per month
                    or the maximum amount permitted by law. The Subscriber will reimburse
                    the Company or its authorized representative for all reasonable
                    collection expenses, including reasonable attorneys’ fees and court
                    costs, for delinquent amounts.
                </p>

                <h4>4. AVAILABILITY </h4>

                <p>
                    4.1 While Company shall use reasonable efforts to make the Product
                    accessible 24 hours, seven days a week, the Company shall have{" "}
                    <u>no liability</u> whatsoever in the event any Subscriber or any
                    Authorized User is unable to access the Product. From time to time,
                    Company and its service providers may perform scheduled or unscheduled
                    maintenance as may be necessary to maintain the proper operation of the
                    Product and access to the Product and the data the Subscriber or an
                    Authorized User have inputted into the Product that is stored by Company
                    and its service providers (“<u>Hosted Data</u>”) may be impaired or
                    interrupted while such maintenance is being performed. Company and its
                    service providers may conduct scheduled maintenance from time to time.
                </p>
                <p>
                    4.2 The Subscriber and any Authorized User is solely responsible for
                    their Internet connection (the speed of which may have a significant
                    impact on the responsiveness of the Product), including all Internet
                    service provider connection charges. The company recommends access to a
                    second internet connection backup for redundancy. The Subscriber and any
                    Authorized User shall also solely be responsible for all computers,
                    other hardware, and software necessary to allow the Subscriber and any
                    Authorized User to establish and maintain a wireless computer network in
                    the Subscriber and such Authorized User offices and to access the
                    Internet, including but not limited to personal computers, printers,
                    scanners, routers, faxes, copiers, modems, personal digital assistants,
                    operating systems, anti-virus software, firewalls, and network software
                    (collectively, the “<u>Office Systems</u>”). The Product site may
                    specify certain minimum requirements that the Subscriber’s or an
                    Authorized User’s Office Systems must meet in order for the Subscriber
                    or an Authorized User to be able to properly use the Product.
                </p>
                <p>
                    4.3 Only the Subscriber and its Authorized Users are authorized to use
                    this Product. NEITHER THE COMPANY NOR OUR SUPPLIERS ARE RESPONSIBLE FOR
                    UNAUTHORIZED ACCESS THAT OCCURS AS A RESULT OF FAILURE TO SAFEGUARD THE
                    SUBSCRIBER’S OR ANY AUTHORIZED USER’S CREDENTIALS OR FOLLOW ANY OTHER
                    REQUIREMENTS IN THIS AGREEMENT.
                </p>

                <h4>5. AUTHORIZED USERS </h4>

                <p>
                    5.1 The number of the Subscriber employees or students who may be
                    registered with the Company as “<u>Authorized Users</u>” at any point in
                    time (the “<u>Authorized Number of Users</u>”) is the number specified
                    in the Order Form. All Authorized Users must be 18 years or older. Using
                    the functionality of the Product, the Subscriber may substitute and
                    remove Authorized Users, but the aggregate number of Authorized Users
                    assigned shall not exceed the Authorized Number of Users purchased by
                    the Subscriber. The Subscriber may increase the Authorized Number of
                    Users from time to time by purchasing an increase from Company. Only
                    Authorized Users may directly access the Product.
                </p>
                <p>
                    5.2 All Authorized Users shall be required to sign a User Agreement and
                    abide by the terms and conditions of the User Agreement, the Terms and
                    Conditions and the Privacy Policy. The Company shall determine in its
                    sole discretion whether or not an Authorized User is no longer
                    authorized to access and use the Product and its decision is final and
                    non-appealable.
                </p>
                <p>
                    5.3 The Subscriber must contact the Company immediately if it becomes
                    aware that a third party has accessed any account that the Subscriber or
                    any Authorized User maintains using a Product or Service. NEITHER THE
                    COMPANY NOR OUR SUPPLIERS ARE RESPONSIBLE FOR UNAUTHORIZED ACCESS THAT
                    OCCURS AS A RESULT OF FAILURE TO SAFEGUARD SUBSCRIBER’S OR ANY
                    AUTHORIZED USER’S CREDENTIALS OR FOLLOW ANY OTHER REQUIREMENTS IN THIS
                    AGREEMENT
                </p>

                <h4>6. USE OF THE PRODUCT; SECURITY; PERMITTED USES </h4>

                <p>
                    6.1 The Subscriber and each Authorized User shall use the Product solely
                    for its internal business purposes and in accordance with any usage
                    limitations or guidelines provided by the Company.
                </p>
                <p>
                    6.2 Company has implemented certain security measures (such as password
                    protection and encryption) and maintain such other safeguards (including
                    virus protection safeguards) in its sole discretion that are reasonably
                    intended to preserve the confidentiality, integrity and availability of
                    Hosted Data. The Subscriber and Authorized User acknowledges and agrees
                    that the Company may monitor, record, and audit its use of the Product
                    in order to protect the security of any Hosted Data and the security of
                    the Company’s information systems. The Subscriber and each Authorized
                    User agrees that the Company may suspend one or more of Authorized User
                    accounts or the Subscriber’s access if necessary to protect the security
                    of Hosted Data or the Company’s information systems or in the event of a
                    violation of this Agreement, any User Agreement, the Terms of Use of the
                    Privacy Policy. The Subscriber, on behalf of itself and its Authorized
                    User, expressly recognize that, although Company shall take such
                    reasonable steps, or cause such reasonable steps to be taken, to prevent
                    security breaches, it is impossible to maintain flawless security. The
                    Subscriber is solely responsible for any damage caused by unauthorized
                    destruction, loss, interception, or alteration of the Hosted Data by
                    unauthorized persons.
                </p>
                <p>
                    6.3 Neither the Subscriber nor any Authorized User shall use the Product
                    in a manner that infringes the trademark, patent, copyright, trade
                    secret or other intellectual property right of any other person. Neither
                    the Subscriber nor any Authorized User shall use the Product to
                    transmit, store or publish any content that is obscene, libelous,
                    threatening or unlawful. The Subscriber and each Authorized User will
                    comply with Company’s Privacy Policy and Terms of Use as may be in
                    effect from time to time and posted on the Company’s web site. If such
                    user policies require the Subscriber to implement specific safeguards,
                    such as unique user accounts with confidential passwords, the Subscriber
                    agrees to implement those safeguards and the Subscriber will require its
                    Authorized Users to comply, as well.
                </p>
                <p>
                    6.4 Company may establish one or more internal user accounts for the
                    Product, which accounts shall enable authorized members of Company’s
                    workforce and its subcontractors to access application data. The company
                    and its subcontractors may access information hosted applications and
                    databases for testing, system maintenance, support, and as needed to
                    investigate alleged privacy violations and security incidents. Such
                    information shall be restricted to authorized personnel on a
                    need-to-know basis. Company shall access such information only for
                    purposes authorized by this Agreement and the other use policies.
                </p>
                <p>
                    6.5 Company may use and disclose the Hosted Data to provide the Product
                    (including submitting such Hosted Data to other health care providers,
                    third party service providers, insurance companies, and other persons as
                    directed by the Subscriber and in accordance with the this Agreement),
                    (b) to aggregate information relating to transactions for statistical
                    analysis and business measures of the performance of the SaaS Services,
                    (c) to monitor the Subscriber and each Authorized User use of the SaaS
                    Services for security purposes, (d) as permitted by the Business
                    Associate Addendum, and (e) to enforce the terms of this Agreement. The
                    Company shall have the right, in its sole discretion, to choose not to
                    disclose Hosted Data to any third party if it has a reasonable belief
                    that the proper consents are not in place form Authorized Users or other
                    third parties or if such disclosure will contravene applicable laws,
                    rules, regulations, directives or orders.
                </p>
                <h4>7. CONTENT. </h4>

                <p>
                    7.1 The Subscriber shall keep backup copies of all of the subscriber’s
                    content. "Content" means (a) any data or information that a Subscriber
                    or an Authorized User enters or stores in connection with a Product, (b)
                    any data or information that a Subscriber or an Authorized User uploads
                    to, downloads from, sends, or receives, or enters in connection with a
                    Service, or (c) any data or information that is obtained from these
                    things (such as calculations, reports, and files that you create using
                    our software). In some cases, the Company or our Suppliers may provide
                    data storage, data visualization or other processing capabilities or
                    services. NEVERTHELESS, THE SUBSCRIBER AND EACH AUTHORIZED USER AGREE
                    THAT NEITHER THE COMPANY NOR ITS SUPPLIERS ARE RESPONSIBLE FOR ANY LOSS
                    OF CONTENT OR HOSTED DATA.
                </p>
                <p>
                    7.2 In order to provide Product functionality, the Company must have
                    access to the Content that the Subscriber and each Authorized Users
                    stores or processes using a Product. The Subscriber and each Authorized
                    User grants the Company, its Suppliers, and each of its affiliate’s
                    permission to collect, send, receive, process, store, alter, and create
                    new information based on the Subscriber’s and each Authorized User’s
                    Content, Credentials, and other information. The Subscriber and each
                    Authorized User further grants to the Company and its Suppliers
                    permission to use information about the Subscriber and each Authorized
                    User and each of its or their experience to provide the Products, or to
                    enhance the Products. In addition, the Subscriber and each Authorized
                    User agrees that the Company, or its Suppliers (when working on their
                    behalf), or the Third-Party Products and Services as defined below, may
                    (but are not obligated to) monitor any Content and record any
                    transactions and actions while using the Products. The Subscriber and
                    each Authorized User agrees that the Company may disclose any
                    information that the Company obtain in this manner if necessary to
                    satisfy Company’s legal obligations, to protect Company and its
                    Suppliers, or to prevent harm or fraud against customers or third
                    parties. The Company or its Suppliers may, in its or their sole
                    discretion, refuse to process, remove, or refuse to allow any Content
                    that the Company has determined, in its sole discretion, is illegal,
                    inappropriate, or in violation of this Agreement or other applicable
                    terms. The Subscriber and each Authorized User hereby waives any of
                    their respective moral rights in any of their respective Content, Data
                    and other information in favor of the Company, its Suppliers and each of
                    its affiliates
                </p>

                <h4>8. INTELLECTUAL PROPERTY; UPDATES</h4>

                <p>
                    8.1 The Company retains all rights, title, and interests in and to the
                    Product, including any intellectual property rights therein this
                    Agreement. The right to use this Product hereunder does not provide the
                    Subscriber or any Authorized User with title to or ownership of the
                    Product, the intellectual property rights embodied herein or any copies
                    or modifications thereof, but only a right of limited remote use under
                    the terms and conditions of this Agreement.
                </p>
                <p>
                    8.2 The Subscriber and each Authorized User hereby grants to the Company
                    a non-exclusive, sublicensable, royalty free, fully paid up, assignable
                    license and right to use any feedback, suggestions, or improvements
                    provided by the Subscriber and each Authorized User for the purpose of
                    improving or enhancing the Product.
                </p>
                <p>
                    8.3 The Company reserves the right to change this Agreement at any time,
                    and the changes will be effective when posted on our website or when the
                    Company notify the Subscriber by other means. The Subscriber’s and each
                    Authorized User’s continued use of a Product or Service following notice
                    indicates the Subscriber’s and each Authorized User’s agreement to such
                    changes.
                </p>
                <p>
                    8.4 The Company retains all rights, title, and interest in and to any
                    Anonymized Data and Subscriber and each Authorized User agree that each
                    of them have no rights therein and waive all moral rights thereto.
                </p>

                <h4>9. ACCESS AND CONTENT</h4>
                <p>
                    9.1 Neither the Subscriber, any Authorized User nor any agent or
                    representative thereof will do any of the following:
                </p>
                <ul>
                    <li>
                        Use, nor permit any third party to use, the Product or Services in a
                        manner that violates any law, regulation, this Agreement, or the Terms
                        of Use or the Privacy Policy. The Subscriber will not remove or modify
                        any copyright or proprietary notices.
                    </li>
                    <li>
                        Give, sell, loan, or distribute the Products or Services to any third
                        party or attempt to transfer this license to any other party.
                    </li>
                    <li>
                        Attempt, or help anybody else to gain unauthorized access to the
                        Products and Services or related systems.
                    </li>
                    <li>
                        Permit any third party to benefit from the use or functionality of the
                        Products or Services via a rental, lease, timesharing, service bureau,
                        hosting service, or other arrangement, or offer them on any
                        file-sharing or application hosting service.
                    </li>
                    <li>
                        Not assist, directly or indirectly, anybody else to gain unauthorized
                        access to the Product or related systems.
                    </li>
                </ul>
                <p>
                    9.2 Neither the Company nor its Suppliers assume any responsibility or
                    liability for any aspect of the Subscriber’s or any Authorized User’s
                    Content or Hosted Data. Subscriber and each Authorized User is
                    responsible for periodically checking its Content, Hosted Data and other
                    data (including any Content or data that have been uploaded, downloaded,
                    sent to, or received from Third-Party Product and Services) for such
                    items
                </p>
                <p>
                    9.3 Without limiting the Terms of Use or the Privacy Policy. the
                    Subscriber and each Authorized User agrees that neither the Subscriber,
                    any Authorized User nor any agent or representative thereof will upload,
                    process, share, or link to any of the following:
                </p>
                <ul>
                    <li>
                        Content that is illegal, fraudulent, inappropriate, or objectionable,
                        or that creates criminal or civil liability under any law.
                    </li>
                    <li>
                        A virus, Trojan horse, worm, or other disruptive or harmful software
                        or data.
                    </li>
                    <li>
                        Any information, software, or Content which is not legally the
                        Subscriber’s or any Authorized User’s without permission from the
                        copyright owner or intellectual property rights owner.
                    </li>
                </ul>
                <p>
                    9.3 The Company retains the right to block any content or other
                    information or data that the Company may deem in our sole discretion to
                    be harmful or not meeting the standards of behavior.
                </p>
                <p>
                    9.4 The Subscriber and each Authorized User are responsible for
                    periodically checking their Content and other data (including any
                    Content or data that have been uploaded, downloaded, sent to, or
                    received from Third-Party Products and Services) for such items.
                </p>

                <h4>10. WARRANTY DISCLAIMER</h4>
                <p>
                    10.1 THE PRODUCT IS PROVIDED "AS IS" WITHOUT WARRANTY OF ANY KIND,
                    EITHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED
                    WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR
                    NONINFRINGEMENT.
                </p>
                <p>
                    10.2 COMPANY MAKES NO REPRESENTATION OR WARRANTY THAT THE PRODUCT OR ANY
                    THIRD PARTY SERVICES SHALL PERFORM ACCURATELY OR RELIABLY, THAT ANY
                    INFORMATION DELIVERED TO SUBSCRIBER, AUTHORIZED USER, OR TO THIRD
                    PARTIES ON SUBSCRIBER’S OR AUTHORIZED USER’S BEHALF, BY OR THROUGH THE
                    PRODUCT OR ANY THIRD PARTY SERVICES WILL BE CORRECT OR COMPLETE, OR THAT
                    USE OF THE PRODUCT OR ANY THIRD PARTY SERVICES WILL OBTAIN FOR
                    SUBSCRIBER OR AUTHORIZED USER ANY CERTAIN RESULTS. THE COMPANY DISCLAIMS
                    ANY AND ALL REPRESENTATIONS AND WARRANTIES, EXPRESS OR IMPLIED, BY
                    OPERATION OF LAW OR OTHERWISE, REGARDING OR RELATING TO THE PRODUCT OR
                    ANY THIRD-PARTY SERVICES OR ANY OTHER SERVICES PRODUCTS OR SERVICES
                    DELIVERED UNDER THIS AGREEMENT. THE COMPANY SPECIFICALLY DISCLAIMS ALL
                    IMPLIED WARRANTIES, INCLUDING WITHOUT LIMITATION ANY WARRANTIES OF
                    MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE (IRRESPECTIVE OF ANY
                    PREVIOUS COURSE OF DEALING BETWEEN THE PARTIES OR CUSTOM OR USAGE OF
                    TRADE), NON-INFRINGEMENT, OR THAT THE PRODUCT OR ANY THIRD-PARTY
                    SERVICES WILL BE UNINTERRUPTED OR ERROR FREE.
                </p>

                <h4>11. LIMITATION OF LIABILITY </h4>
                <p>
                    11.1 TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, COMPANY AND ITS
                    SUPPLIERS AND LICENSORS WILL NOT BE LIABLE FOR ANY LOSS OF REVENUE,
                    PROFITS OR GOODWILL OR FOR ANY SPECIAL, INCIDENTAL, INDIRECT,
                    CONSEQUENTIAL OR PUNITIVE DAMAGES OR LOSSES RESULTING FROM COMPANY’S OR
                    THE PRODUCT’S OR ANY THIRD PARTY SERVICES’ PERFORMANCE OR FAILURE TO
                    PERFORM PURSUANT TO THE TERMS OF THIS AGREEMENT, FROM THE FURNISHING,
                    PERFORMANCE OR LOSS OF USE OF SUCH PRODUCTS OR SERVICES, INCLUDING,
                    WITHOUT LIMITATION, FROM ANY INTERRUPTION OF BUSINESS, WHETHER RESULTING
                    FROM BREACH OF CONTRACT OR OTHER LEGAL LIABILITY WHATSOEVER, EVEN IF
                    COMPANY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
                </p>
                <p>
                    11.2 THE MAXIMUM AGGREGATE LIABILITY OF THE COMPANY OR ANY OF ITS
                    SUPPLIERS ARISING OUT OR RELATING TO THIS AGREEMENT SHALL NOT EXCEED THE
                    TOTAL FEES PAID BY THE SUBSCRIBER TO COMPANY HEREUNDER DURING THE TWELVE
                    MONTHS PREVIOUS TO THE EVENTS GIVING RISE TO SUCH CLAIM.
                </p>

                <h4>12. TERM AND TERMINATION </h4>

                <p>
                    12.1 This Agreement shall commence on the date that all Fees have been
                    paid and, unless suspended or terminated early, shall continue for the
                    duration of the subscription period listed on the Order Form (the
                    “Term”). If the right to access the Product has been suspended or if the
                    Subscriber or any Authorized User does not have access to the Product,
                    for any reason, the period during which the Subscriber’s or any
                    Authorized User’s account has been suspended or which the Subscriber or
                    any Authorized User does not have access shall not increase the length
                    of the Term.
                </p>
                <p>
                    12.2 The Subscriber may terminate this Agreement for any or no reason by
                    providing Company at least thirty (30) days prior written notice (or, if
                    the Product provides a termination of subscription functionality in the
                    Product, by notifying Company at least thirty (30) days in advance
                    through such functionality). If any Fees become due before the effective
                    date of termination (regardless whether notice of termination has
                    already been delivered), the Subscriber shall be required to pay such
                    fees in full. .
                </p>
                <p>
                    12.3 Notwithstanding the foregoing, Company may terminate this Agreement
                    (and all licenses hereunder) immediately in the event that any amounts
                    owed are not paid when due, or for violation of this Agreement, the
                    Terms of Use or the Privacy Agreement or for any other reason in the
                    Company’s discretion.
                </p>
                <p>
                    12.4 In the event this Agreement is terminated or not renewed for any
                    reason, then all rights and licenses granted to the Subscriber and any
                    Authorized Users hereunder to the Product shall terminate and each of
                    the foregoing persons shall cease to use and access the Product and the
                    Content. Upon notice to Company within thirty (30) days after the
                    termination of the rights to the Product as described above, you may
                    request the Company to transfer an electronic copy of the Hosted Data
                    (in the format in which it is stored by Company) to you in exchange for
                    the payment of a fee set by the Company If you do not provide the
                    Company with notice within the period described above or pay the fee
                    within ten (10) days upon demand by the Company, the Company shall have
                    no obligation to provide you the Content thereafter.
                </p>
                <p>
                    12.5 In addition, the following terms and conditions shall survive any
                    termination or expiration of this Agreement: Sections 2.2, 3.2, 3.3,
                    4.3, and Articles 6, 7,8,9,10, 11, 12, 13 and 14
                </p>

                <h4>13. GOVERNING LAW </h4>

                <p>
                    This Agreement shall be governed by and construed in accordance with the
                    laws of the Commonwealth of Massachusetts without regard to its conflict
                    of laws principles. Other than as necessary to enforce any final
                    judgment, award or determination, any action brought pursuant to or in
                    connection with this Agreement shall be brought only in the state or
                    federal courts within the Commonwealth of Massachusetts without regard
                    to its conflict of law’s provisions. In any such action, both parties
                    submit to the personal jurisdiction of the courts of the Commonwealth of
                    Massachusetts and waive any objections to venue of such courts.
                </p>
                <p>
                    NOTWITHSTANDING ANYTHING TO THE CONTRARY, THE COMPANY MAY AT ANY TIME
                    SEEK INJUNCTIONS OR OTHER FORMS OF EQUITABLE RELIEF FROM ANY COURT OF
                    COMPETENT JURISDICTION. ANY AND ALL DISPUTES MUST BE BROUGHT IN THE
                    PARTIES' INDIVIDUAL CAPACITY AND NOT AS A PLAINTIFF OR CLASS MEMBER IN
                    ANY PURPORTED CLASS OR REPRESENTATIVE PROCEEDING. YOU AGREE THAT YOU
                    WAIVE THE RIGHT TO FILE A LAWSUIT AND THE RIGHT TO A TRIAL BY JURY. IN
                    ADDITION, YOU AGREE TO WAIVE THE RIGHT TO PARTICIPATE IN A CLASS ACTION
                    OR LITIGATE ON A CLASS-WIDE BASIS. YOU AGREE THAT YOU HAVE EXPRESSLY AND
                    KNOWINGLY WAIVED THESE RIGHTS. COMPANY RESERVES THE RIGHT TO ENFORCE ITS
                    INTELLECTUAL PROPERTY RIGHTS IN ANY APPLICABLE VENUE AND IN ANY
                    JURISDICTION.
                </p>
                <p>ALL ARBITRATION PROCEEDINGS SHALL OCCUR IN BOSTON, MASSACHUSETTS.</p>

                <h4>14. MISCELLANEOUS </h4>

                <p>
                    14.1 This Agreement constitutes the entire agreement between the Parties
                    with respect to the subject matter hereof and supersedes all prior or
                    contemporaneous agreements, representations, warranties, and
                    understandings.
                </p>
                <p>
                    14.2 The Subscriber shall not assign, delegate, sublicense, or transfer
                    any of its obligations, responsibilities, rights or interests under this
                    Agreement without the written consent of the Company, except to a
                    successor in a merger or a sale of all or substantially all of
                    Subscriber’s capital stock and/or assets, as the case may be.
                </p>
                <p>
                    14.3 In the event that the Company is unable to perform any of its
                    obligations under this Agreement because of causes beyond its reasonable
                    control or because of any Act of God, accident to equipment or
                    machinery; any fire, flood, hurricane, tornado, storm or other weather
                    condition; any war, act of war, act of public enemy, terrorist act,
                    sabotage, riot, civil disorder, act or decree of any governmental body;
                    any failure of communications lines, transportation, light, electricity
                    or power; any earthquake, civil disturbance, commotion, lockout, strike
                    or other labor or industrial disturbance; or any illness, pandemic,
                    epidemic, quarantine, death or any other natural or artificial disaster
                    (each, a “Force Majeure Event”) the Company shall give notice to the
                    Subscriber and shall devote commercially reasonable efforts to resume
                    performance. Upon receipt of such notice, all obligations under this
                    Agreement shall be immediately suspended and performance times shall be
                    considered extended for a period of time equivalent to the time lost
                    because of any such delay. Nothing provided herein shall excuse the
                    delay of any payment that is validly due by the Subscriber under this
                    Agreement.
                </p>
                <p>
                    14.4 Unless expressly stated otherwise herein, any notice, demand,
                    request or delivery required or permitted to be given by a party
                    pursuant to the terms of this Agreement shall be in writing and shall be
                    deemed given (a) when delivered personally, (b) on the next business day
                    after timely delivery to an overnight courier, (c) on the third business
                    day after deposit in the U.S. mail (certified or registered mail return
                    receipt requested, postage prepaid), (d) when delivered via email to the
                    notified party’s email provider for delivery to such notified party, and
                    (e) upon confirmation of receipt by facsimile transmission; in each case
                    addressed to the party at such party’s address as set forth below or as
                    subsequently modified by the receiving party pursuant to written notice:
                </p>

                <p>If to Company info@powerofpatients.com</p>
                <p>
                    If to you: At the address and email address that you have registered on
                    the Product.
                </p>
                <div className='terms-sign-container'>
                    <h4>For Authorized User </h4>
                    <p>
                        By completing the information and clicking the square below, I, as an Authorized User, hereby agrees to these terms.
                    </p>
                    <div>
                        {(firstName && lastName) &&
                            <div className='terms-sign'>
                                <SubmitButton onClick={() => { setTermsAgreed(true) }}>
                                    Sign
                                </SubmitButton>
                                <p>{firstName + " " + lastName}</p>
                            </div>
                        }
                        {(!firstName || !lastName) &&
                            <>
                                <div className='terms-line' />
                            </>
                        }

                    </div>
                </div >

                {(firstName && lastName) &&
                    <SubmitButton
                        onClick={() => {
                            setShowTermsAndConditions(false)
                            setErrorMessage('');
                        }}
                        disabled={!termsAgreed}
                        greyout={!termsAgreed}
                    >
                        I agree to the terms and conditions.
                    </SubmitButton>
                }

                {(!firstName || !lastName) &&
                    <SubmitButton
                        onClick={() => { setShowTermsAndConditions(false) }}>
                        Go back to enter your full name
                    </SubmitButton>
                }
            </div>
        </>
    )
}

export default TermsAndConditions